// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const DATA_TABLE = gql`
  query ListPage(
    $navigationSlug: String!,
    $dossierTypeSlug: String!,
    $page: Int,
    $first:Int,
    $sorters: [DataGridSorterInput],
    $filters: [DataGridFilterInput]
  ) {
    listTitle: dossierTypeListTitleByNavigationSlug(navigationSlug: $navigationSlug, dossierTypeSlug: $dossierTypeSlug)
    list: dossierTypeListByNavigationSlug(
      navigationSlug: $navigationSlug,
      dossierTypeSlug: $dossierTypeSlug,
      page: $page,
      first: $first,
      sorters: $sorters,
      filters: $filters
    ) {
      data {
        data
      }
      dataGridInfo {
        columns {
          name
          label
        }
        filters {
          name
          applied
          data
          metadata
          __typename
        }
        sorters {
          name
          applied
          direction
        }
        metadata
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export default DATA_TABLE;
